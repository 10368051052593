import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';


const UserLoginDropdown = ({ handleGoogleLoginSuccess, handleGoogleLoginFailure }) => {

    return (

        <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                Увійти
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {/* Google Login */}
                <div className="p-3">
                    <div className="googleSignIn">
                        <div className='mb-2'><small>Зареєструйтеся, щоб спілкуватися з нашим Штучним Інтелектом</small></div>
                        <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onError={handleGoogleLoginFailure}
                            buttonText="Sign in with Google"
                        />
                    </div>
                </div>

                {/* <Dropdown.Divider /> */}

                {/* Альтернативний логін через логін та пароль */}
                {/* <div className="p-3">
                    <Form >
                        onSubmit={handleLogin}
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                            // type="email"
                            // placeholder="Enter email"
                            // value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword" className="mt-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                            // type="password"
                            // placeholder="Password"
                            // value={password}
                            // onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" className="mt-3">
                            Login
                        </Button>
                    </Form>
                </div> */}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default UserLoginDropdown;