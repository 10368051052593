import React from 'react';
import { ReactComponent as Logo } from '../../assets/images/wairex-logo.svg';

import './AboutArea.css';

const AboutArea = () => (
    <>
        <div className="text-white mt-5">
            <div className='w-100'>
                <Logo className='my-2 mx-auto d-block' style={{ height: '80px', width: 'auto', }} />
            </div>
            <p>Wairex — це інноваційна платформа зі штучним інтелектом, яка надає бізнесам можливість створювати власних ШІ асистентів. Ці асистенти можуть автоматично відповідати на запити клієнтів, консультувати їх, просувати та продавати ваші продукти та послуги.</p>

            <p>Платформа Wairex пропонує гнучкі варіанти інтеграції: ви можете встановити асистента як віджет на вашому вебсайті або розмістити його на окремому домені, забезпечуючи клієнтам доступ до вашого цифрового помічника в будь-якому середовищі.</p>
        </div>
    </>
);

export default AboutArea;
