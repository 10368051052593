import React from 'react';
import { ReactComponent as IconUserPlus } from '../../assets/icons/user-plus-solid.svg';
import { ReactComponent as IconLogout } from '../../assets/icons/right-from-bracket-solid.svg';
import { ReactComponent as IconCircleUp } from '../../assets/icons/circle-chevron-up-solid.svg';
import { ReactComponent as IconCircleDown } from '../../assets/icons/circle-chevron-down-solid.svg';
import UserLoginDropdown from "../UserLoginDropdown/UserLoginDropdown";

import './Header.css';

const Header = ({ loggedIn, picture, handleGoogleLoginSuccess, handleGoogleLoginFailure, handleGoogleLogout }) => (
    <>
        <div className="col-1 my-2 d-flex">
            <div className='icon-wrap my-auto'>
                <IconCircleUp className='m-2 icon-height text-secondary cursor-pointer' title="Прокрутити вверх чату" />
            </div>
            <div className='icon-wrap my-auto'>
                <IconCircleDown className='m-2 icon-height text-secondary cursor-pointer' title="Прокрутити вниз чату" />
            </div>
        </div>
        <div className="col-10 my-2 d-flex justify-content-center">
            <div className="d-flex">
                <h4 className="m-auto width-fit-content height-fit-content test-secondary px-4">
                    Інтелектуальна асистент платформа
                </h4>
            </div>
        </div>
        <div className="col-1 my-2 d-flex justify-content-end">
            {
                !loggedIn ? (
                    <div className="googleSignIn mt-20">
                        <UserLoginDropdown
                            handleGoogleLoginSuccess={handleGoogleLoginSuccess}
                            handleGoogleLoginFailure={handleGoogleLoginFailure}
                        />
                    </div>) : (
                    <>
                        {picture ? (
                            <div className='icon-wrap my-auto'>
                                <img src={picture}
                                    className='m-2 external-user-icon cursor-pointer'
                                    // style={{ height: '32px', width: '32px' }}
                                    alt="User profile"
                                />
                            </div>
                        ) : (
                            <div className='icon-wrap my-auto'>
                                <IconUserPlus className='m-2 icon-height text-secondary cursor-pointer'

                                />
                            </div>
                        )}

                        < div className='icon-wrap my-auto'>
                            <IconLogout className='m-2 icon-height text-secondary cursor-pointer'
                                title="Вийти"
                                onClick={handleGoogleLogout}
                            />
                        </div>
                    </>
                )
            }
        </div>
    </>
);

export default Header;
