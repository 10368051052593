import React from 'react';
import { ReactComponent as IconCircleQuestion } from '../../assets/icons/circle-question-solid.svg';
import { ReactComponent as IconSidePanel } from '../../assets/icons/table-columns-solid.svg';

import './SidePanel.css';

const questions = [
    "Як створити власного ШІ-асистента?",
    "Звідки ШІ-асистент буде знати все про мене, або мій бізнес?",
    "Чи зможе ШІ-асистент займатися бізнесом замість мене?",
    "Що саме вміє ШІ-асистент?",
    "Наскільки дороге задоволення, мати власного ШІ-асистента?",
    "Як інтегрувати ШІ-асистента з існуючими бізнес-процесами?",
    "Чи можна створити ШІ-асистента персонально для себе, а не для бізнесу?",
    // Додай інші питання за потребою
];


const SidePanel = ({ sendMessage }) => {

    const handleSendMessage = (message) => {
        if (message.trim()) {
            sendMessage({ text: message, image: null });
        }
    };

    return (
        <>
            <div className="row fixed-height-row">
                <div className="col">
                    <div className='icon-wrap my-2'>
                        <IconSidePanel className='m-2 icon-height text-secondary cursor-pointer' />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col fixed-height-left-column custom-scrollbar">


                    <h6 className="text-secondary pt-4">Часті питання:</h6>
                    {questions.map((question, index) => (
                        <div key={index}
                            className="list-questions-item my-2 p-2 d-flex align-items-center bg-black text-white"
                            onClick={() => handleSendMessage(question)}
                        >
                            <div className="cursor-pointer me-2">
                                <IconCircleQuestion className='icon-height text-white' />
                            </div>
                            <div className="list-questions-item-text" title={question}>{question}</div>
                        </div>
                    ))}

                </div>
            </div>
        </>
    )
};

export default SidePanel;
