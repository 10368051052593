// useSocket.js
import { useEffect, useState } from 'react';
import io from "socket.io-client";

// const ENDPOINT = 'http://localhost:5000';
// const ENDPOINT = 'https://wairex.ai';
const REACT_APP_ENDPOINT = process.env.REACT_APP_ENDPOINT;
const REACT_APP_PATH = process.env.REACT_APP_PATH;


console.log(process.env);


console.log(REACT_APP_ENDPOINT, REACT_APP_PATH);

let socket;

const useSocket = (name, room, subdomain) => {
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        // socket = io(ENDPOINT);

        // Підключаємося до Socket.io через /api/socket.io
        socket = io(REACT_APP_ENDPOINT, {
            path: REACT_APP_PATH, //'/api/socket.io',  // Вказуємо шлях до сокетів, щоб вони працювали через проксі на /api
            transports: ['websocket', 'polling'],  // Вказуємо підтримку websocket і резервного polling
            withCredentials: true,  // Дозволяємо передавати куки та автентифікаційні дані між доменами
        });

        console.log(socket);

        if (name && room) {
            socket.emit('join', { name, room, subdomain }, (error) => {
                if (error) {
                    alert(error);
                }
            });
        }

        socket.on('message', (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        return () => {
            socket.disconnect();
        };
    }, [name, room, subdomain]);

    const sendMessage = (messageData, callback) => {
        socket.emit('sendMessage', messageData, callback);
    };

    return { messages, sendMessage };
};

export default useSocket;
