import React, { useEffect, useRef } from 'react';

import Message from './Message/Message';

import './Messages.css';

const Messages = ({ messages, name, picture }) => {
  // Створюємо ref для контейнера з повідомленнями
  const messagesEndRef = useRef(null);

  // Використовуємо useEffect для автоматичної прокрутки до низу при оновленні повідомлень
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="messages">
      {messages.map((message, i) => (
        <div
          key={i}
          className={`d-flex ${message.user === name.trim().toLowerCase() ? 'justify-content-end' : 'justify-content-start'
            }`}
        >
          <Message
            message={message}
            name={name}
            picture={picture} />
        </div>
      ))}
      {/* Елемент для прокрутки до кінця */}
      <div ref={messagesEndRef} />
    </div>
  );

};

export default Messages;
