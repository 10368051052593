import React from 'react';
import ReactDOM from 'react-dom/client'; // Новий імпорт

import App from './App';

// Створюємо root елемент для рендерингу
const root = ReactDOM.createRoot(document.getElementById('root'));

// Рендеримо компонент App
root.render(<App />);
