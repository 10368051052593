import React, { useState } from 'react';
import './Input.css';
import { ReactComponent as IconSend } from '../../assets/icons/paper-plane-solid.svg';

const Input = ({ sendMessage }) => {
  const [message, setMessage] = useState('');

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message.trim()) {
      sendMessage({ text: message, image: null });
      setMessage('');
    }
  };

  return (
    <form className="form bg-black m-auto" onSubmit={handleSendMessage}>
      <input
        className="input text-white"
        type="text"
        placeholder="Повідомлення для Wairex..."
        value={message}
        onChange={({ target: { value } }) => setMessage(value)}
      />
      <div className="sendButton" onClick={handleSendMessage}>
        <IconSend className='icon-height text-secondary' />
      </div>
    </form>
  );
};

export default Input;
