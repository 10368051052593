// useAuth.js
import { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const useAuth = () => {
    const [user, setUser] = useState({
        name: '',
        email: '',
        picture: '',
        loggedIn: false,
    });

    useEffect(() => {
        const storedName = localStorage.getItem('userName');
        const storedEmail = localStorage.getItem('userEmail');
        const storedPicture = localStorage.getItem('userPicture');

        if (storedName && storedEmail) {
            setUser({
                name: storedName,
                email: storedEmail,
                picture: storedPicture,
                loggedIn: true,
            });
        }
    }, []);

    const handleGoogleLoginSuccess = (credentialResponse) => {
        const decodedToken = jwtDecode(credentialResponse.credential);
        setUser({
            name: decodedToken.name,
            email: decodedToken.email,
            picture: decodedToken.picture,
            loggedIn: true,
        });
        localStorage.setItem('userName', decodedToken.name);
        localStorage.setItem('userEmail', decodedToken.email);
        localStorage.setItem('userPicture', decodedToken.picture);
    };

    const handleGoogleLogout = () => {
        localStorage.removeItem('userName');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userPicture');
        setUser({
            name: '',
            email: '',
            picture: '',
            loggedIn: false,
        });
    };

    const handleGoogleLoginFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    return {
        user,
        handleGoogleLoginSuccess,
        handleGoogleLogout,
        handleGoogleLoginFailure,
    };
};

export default useAuth;
