import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import SidePanel from '../SidePanel/SidePanel'
import Header from '../Header/Header'
import AboutArea from '../AboutArea/AboutArea'
import Messages from '../Messages/Messages';
import Input from "../Input/Input";

import useAuth from '../../hooks/useAuth';
import useSocket from '../../hooks/useSocket';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Welcome.css';

const SUBDOMAIN = 'wairex';


export default function SignIn() {
    const { user, handleGoogleLoginSuccess, handleGoogleLogout, handleGoogleLoginFailure } = useAuth();
    console.log(user.name, user.email, SUBDOMAIN);
    const { messages, sendMessage } = useSocket(user.name, user.email, SUBDOMAIN);
    console.log(window.location.hostname, window.location.port)

    return (
        <GoogleOAuthProvider clientId="1035941395411-qgb3h4he8u7qhh2d68c8k2i3na5rtau0.apps.googleusercontent.com">
            <div className="text-white-50">
                <div className="d-flex">
                    <div className="fixed-width-column vh-100 bg-black min-vh-100 d-none d-sm-block container">
                        <SidePanel
                            sendMessage={sendMessage}
                        />
                    </div>
                    <div className="flex-fill vh-100 bg-dark container-fluid">
                        {/* custom-scrollbar overflow-auto position-relative */}
                        <div className='row fixed-height-row'>
                            <Header
                                loggedIn={user.loggedIn}
                                picture={user.picture}
                                handleGoogleLoginSuccess={handleGoogleLoginSuccess}
                                handleGoogleLoginFailure={handleGoogleLoginFailure}
                                handleGoogleLogout={handleGoogleLogout}
                            />
                        </div>
                        <div className="row overflow-auto custom-scrollbar" style={{ height: 'calc(100vh - 52px - 74px)' }} >
                            <div className="col m-auto max-width-content" >
                                <AboutArea />
                                <Messages
                                    messages={messages}
                                    name={user.name}
                                    picture={user.picture} />
                            </div>
                        </div>
                        <div className="col m-auto max-width-content" style={{ height: '74px', overflow: 'hidden' }}>
                            <Input
                                sendMessage={sendMessage}
                            />
                            <div className="mx-auto width-fit-content" style={{ lineHeight: '1rem' }}><small className="text-secondary">Wairex може помилятися. Перевіряйте важливу інформацію.</small></div>
                        </div>
                    </div>
                </div>
            </div >
        </GoogleOAuthProvider >
    );
}
